import React from 'react';
import { DownloadReport } from '../../containers/DownloadReport';

export interface DownloadReportPageProps {
	params: {
		code: string;
	};
}

const DownloadReportPage = ({ params }: DownloadReportPageProps) => (
	<DownloadReport code={params.code} />
);

export default DownloadReportPage;
